import React from 'react';
import { MdStar, MdStarHalf, MdStarBorder } from 'react-icons/md';

const StarRating = ({ rating }) => {
  const totalStars = 5;

  return (
    <div>
      {[...Array(totalStars)].map((star, index) => {
        const starValue = index + 1;
        return (
          <span key={index}>
            {starValue <= rating ? (
              <MdStar color="black" />
            ) : starValue - 0.5 <= rating ? (
              <MdStarHalf color="black" />
            ) : (
              <MdStarBorder color="black" />
            )}
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
