import { companyName, companyData } from "../constants";

const {
	URL,
} = companyData[companyName];

async function logEvent(eventData, token) {
    const response = await fetch(URL + '/api/event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`,
      },
      body: JSON.stringify(eventData),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Error logging event: ${errorData}`);
    }
  
    return await response.json();
  }
  
  export default logEvent;