export const companyName = 'glamnetic';
export const companyData = {
    "base": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "None",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://jennai.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "base",
        "cssFileName": "base",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": false,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your skincare expert & shopping concierge.\n\nWhat can I do for you today? \n\n\u2022 A personalized skincare consultation. \n\u2022 Answer product questions. \n\u2022 Answer more technical skin questions. \n\u2022 Help with existing order. \n\u2022 Something else.",
        "primaryColor": "rgb(0, 0, 0)",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "",
        "sampleMessageTwo": "",
        "secondaryColor": "rgb(255, 255, 255)",
        "shopifyURL": null,
        "shortIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your skincare expert & shopping concierge. How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "rgb(255, 255, 255)",
        "twoSuggestions": false,
        "updateURLMethod": "none",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": null
    },
    "drloretta": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "http://3.92.62.114",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://drloretta.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "drloretta",
        "cssFileName": "drloretta",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": true,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your skincare expert & shopping concierge.\n\nWhat can I do for you today? \n\n\u2022 A personalized skincare consultation. \n\u2022 Answer product questions. \n\u2022 Answer more technical skin questions. \n\u2022 Help with existing order. \n\u2022 Something else.",
        "primaryColor": "#705C4F",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_drloretta.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "How gentle are your cleansers?",
        "sampleMessageTwo": "",
        "secondaryColor": "white",
        "shopifyURL": "https://drloretta.com",
        "shortIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your skincare expert & shopping concierge. How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "white",
        "twoSuggestions": false,
        "updateURLMethod": "products",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "http://http://3.92.62.114:3003"
    },
    "glamnetic": {
        "API_POLLING_INTERVAL": 5000,
        // "URL": 'http://localhost:1000',
        "URL": "https://glamnetic-demo-backend.jennai.com",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://glamnetic.com/",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/glamneticLogo.png",
        "companyName": "glamnetic",
        "cssFileName": "glamnetic",
        "firstMessageIsHtml": true,
        "hideConsoleLogs": true,
        "isOnShopify": true,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi! I'm Jenn, your personal Glamnetic Shopping Assistant. Is there something specific you are looking for today?\n\nTo check the status of your order, click https://www.glamnetic.com/pages/track-my-order",
        "primaryColor": "rgb(236, 192, 197)",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_glamnetic.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "Shop With A Live Beauty Expert >",
        "sampleMessageTwo": "Customer Service >",
        "secondaryColor": "rgb(255, 255, 255)",
        "shopifyURL": "https://glamnetic.com",
        "shortIntroMessage": "Hi, I am Jenn, your nail and lash expert! \ud83d\udc4b How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "rgb(255, 255, 255)",
        "twoSuggestions": true,
        "updateURLMethod": "products",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "https://glamnetic-demo-fake-frontend.jennai.com"
    },
    "nood": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "http://35.153.205.154",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://trynood.com/",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "nood",
        "cssFileName": "nood",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": true,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your hair removal expert & shopping concierge.\n\nWhat can I do for you today? \n\n\u2022 A personalized hair removal consultation. \n\u2022 Answer product questions. \n\u2022 Answer more technical laser hair removal questions. \n\u2022 Help with existing order. \n\u2022 Something else.",
        "primaryColor": "#ffdda3",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_nood.json",
        "sampleMessageOne": "What's the process of using Nood?",
        "sampleMessageTwo": "How well does Nood remove hair?",
        "secondaryColor": "white",
        "shopifyURL": "https://trynood.com/",
        "shortIntroMessage": "nood shortIntroMessage",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "rgb(0, 0, 0)",
        "twoSuggestions": false,
        "updateURLMethod": "products",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "http://35.153.205.154:3003"
    },
    "olukai": {
        "API_POLLING_INTERVAL": 5000,
        "URL": null,
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://olukai.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "olukai",
        "cssFileName": "drloretta",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": true,
        "isShopifyDeployed": false,
        "longIntroMessage": "olukai longIntroMessage",
        "primaryColor": "black",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info__olukai.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "black hiking shoes under $60",
        "sampleMessageTwo": "white beach walking shoes, comfortable",
        "secondaryColor": "white",
        "shopifyURL": null,
        "shortIntroMessage": "olukai shortIntroMessage",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "rgb(0, 0, 0)",
        "twoSuggestions": false,
        "updateURLMethod": "products",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": null
    },
    "piperwai": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "https://100.25.190.46",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://piperwai.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "piperwai",
        "cssFileName": "piperwai",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": true,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your pit-care expert and shopping concierge. How can I help you?",
        "primaryColor": "#00cfb4",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_piperwai.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "Feeling lazy? Let us shop for you",
        "sampleMessageTwo": "What ingredients are in your deoderant?",
        "secondaryColor": "white",
        "shopifyURL": "https://piperwai.com",
        "shortIntroMessage": "Hi, I'm Jenn! \ud83d\udc4b I'm your pit-care expert and shopping concierge. How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "white",
        "twoSuggestions": false,
        "updateURLMethod": "none",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "https://100.25.190.46:3003"
    },
    "possible": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "https://18.212.15.28",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://possibleevent.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "possible",
        "cssFileName": "possible",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": false,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I am Christian, you POSSIBLE conference concierge! \ud83d\udc4b How can I help you?",
        "primaryColor": "#01a9b6",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_possible.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "When is Gary V speaking?",
        "sampleMessageTwo": "What is the best CMO AI event?",
        "secondaryColor": "black",
        "shopifyURL": null,
        "shortIntroMessage": "Hi, I am Christian, you POSSIBLE conference concierge! \ud83d\udc4b How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "white",
        "twoSuggestions": false,
        "updateURLMethod": "none",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "https://18.212.15.28:3003"
    },
    "shiseido": {
        "API_POLLING_INTERVAL": 5000,
        "URL": null,
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://shiseido.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "shiseido",
        "cssFileName": "shiseido",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": false,
        "isShopifyDeployed": false,
        "longIntroMessage": "shiseido longIntroMessage",
        "primaryColor": "black",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_shiseido.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "",
        "sampleMessageTwo": "",
        "secondaryColor": "white",
        "shopifyURL": null,
        "shortIntroMessage": "shiseido shortIntroMessage",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "white",
        "twoSuggestions": false,
        "updateURLMethod": "products",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "https://shiseido.com"
    },
    "sokolin": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "http://100.26.158.241",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://sokolin.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "sokolin",
        "cssFileName": "sokolin",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": false,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I'm Dave! \ud83d\udc4b I'm your wine expert and shopping concierge. How can I help you?",
        "primaryColor": "black",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_sokolin.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "Tuesday night red wine",
        "sampleMessageTwo": "Impress my boss, need promotion",
        "secondaryColor": "black",
        "shopifyURL": null,
        "shortIntroMessage": "Hi, I'm Dave! \ud83d\udc4b I'm your wine expert and shopping concierge. How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "white",
        "twoSuggestions": false,
        "updateURLMethod": "sku",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "http://100.26.158.241:3003"
    },
    "yurman": {
        "API_POLLING_INTERVAL": 5000,
        "URL": "http://3.84.28.155",
        "accentColor": "rgb(182, 99, 123)",
        "baseUrl": "https://davidyurman.com",
        "cartIsOn": false,
        "companyLogo": "https://jennai-storage.s3.amazonaws.com/logo.png",
        "companyName": "yurman",
        "cssFileName": "yurman",
        "firstMessageIsHtml": false,
        "hideConsoleLogs": true,
        "isOnShopify": false,
        "isShopifyDeployed": false,
        "longIntroMessage": "Hi, I am Jenn, your jewelry expert! \ud83d\udc4b How can I help you?",
        "primaryColor": "rgb(0, 0, 0)",
        "productCardDirectsToModal": true,
        "productInfoFileName": "./product_info_yurman.json",
        "quantityIsAdjustable": false,
        "sampleMessageOne": "Shop With A Live Product Expert >",
        "sampleMessageTwo": "Customer Service >",
        "secondaryColor": "rgb(255, 255, 255)",
        "shopifyURL": "None",
        "shortIntroMessage": "Hi, I am Jenn, your jewelry expert! \ud83d\udc4b How can I help you?",
        "shouldLogCart": false,
        "showAddToBagButton": true,
        "showCarts": false,
        "showQuantityAdjusterArrows": false,
        "showReviews": false,
        "strokeColor": "rgb(0, 0, 0)",
        "twoSuggestions": false,
        "updateURLMethod": "none",
        "userInputType": "textOnly",
        "usingGorgias": false,
        "websiteURL": "http://3.84.28.155:3003"
    }
};
