// ProductCard.js
import React, { useState } from "react";
import QuantityAdjuster from "../../QuantityAdjusterShopify";
import StarRating from "../StarRating";
import { companyName, companyData } from "../../constants";

const {
	showAddToBagButton,
	showQuantityAdjusterArrows
} = companyData[companyName];

const ShopifyProductCard = ({
  companyName,
  url,
  setModalOpen,
  isModalOpen,
  setModalType,
  productUrl,
  setProductUrl,
  product_info,
  isFullScreen,
  handleQuantityChange,
  setCheckoutId,
  isOnShopify,
}) => {

  const [quantity, setQuantity] = useState(0);
  if (url[0] == "(") {
    url = url.slice(1);
  }
  if (url[url.length - 1] == ")") {
    url = url.slice(0, -1);
  }

  function filterYurmanProducts(products, title, bandSize) {
    const productsArray = Object.values(products);
    console.log(
      productsArray.filter(
        (product) =>
          product.primaryTitle === title && product.band_size === bandSize
      )
    );
    return productsArray.filter(
      (product) =>
        product.primaryTitle === title && product.band_size === bandSize
    );
  }
  const currentProduct = product_info[url];
  const matchingProducts = filterYurmanProducts(
    product_info,
    currentProduct.primaryTitle,
    currentProduct.band_size
  );
  let secondaryTitle = product_info[url]?.["Secondary Title"] || "";
  let starRatings = product_info[url]?.["Rating"] || 5.0;
  let compareAtPrice = product_info[url]?.["Compare at Price"] || null;

  let productImage = product_info[url]["Product Image"];
  return (
    <div className="product-card">
      <div
        className="product-card-first-row"
        onClick={() => {
          if (isFullScreen) {
            // In full-screen mode, just set the modal type and ensure the modal is open
            setModalType("ExpandedProduct");
            setProductUrl(url);
            if (!isModalOpen) {
              setModalOpen(true);
            }
          } else {
            // In non-full-screen mode, toggle the modal state
            setModalOpen(!isModalOpen);
            setModalType("ExpandedProduct");
            setProductUrl(url);
          }
        }}
      >
        <div className="image-container">
          <img
            src={productImage}
            alt="productImage"
            className="product-image"
          />
        </div>
        <h2 className="product-title">{product_info[url]["Product Name"]}</h2>
        <p className="secondary-title">{secondaryTitle}</p>
        <StarRating rating={starRatings} color={"black"} />
        {compareAtPrice ? (
        <div className='price-container'>
          <div className="product-price" style={{ textDecoration: 'line-through' }}>${compareAtPrice}</div>
          <div className="product-discount-price">${product_info[url]["Product Price"]}</div>
        </div>
      ) : (
        <div className="product-price">${product_info[url]["Product Price"]}</div>
      )}
        {showAddToBagButton &&
        <button className="add-to-bag-button">ADD TO BAG</button>
        }
        {/*      <div className="product-card-add-to-cart" onClick={()=>{setModalOpen(!isModalOpen); setModalType('Cart')}}>
        Add to Cart
      </div>*/}
        {(!isOnShopify && showQuantityAdjusterArrows) && (
          <div className="cart-product-card-quantity-selector">
            <svg
              onClick={() => setQuantity(quantity - 1)}
              disabled={quantity <= 1}
              className="cart-product-card-quantity-down-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M9 1L5 5L1 1"
                stroke="#8F959E"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="cart-product-card-quantity-selector-value">
              {quantity}
            </span>
            <svg
              onClick={() => setQuantity(quantity + 1)}
              className="cart-product-card-quantity-up-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
            >
              <path
                d="M1 5L5 0.999999L9 5"
                stroke="#8F959E"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        )}
        {(isOnShopify && showQuantityAdjusterArrows) && companyName !== "glamnetic" && (
          <QuantityAdjuster
            variantId="40595966230606"
            onQuantityChange={handleQuantityChange}
            setCheckoutId={setCheckoutId}
          />
        )}
      </div>
    </div>
  );
};

export default ShopifyProductCard;
