import React, { useState } from "react";
import { companyName, companyData} from '../../constants';
import ShopifyQuantityAdjuster from '../../ShopifyQuantityAdjuster';

const {
  hideConsoleLogs,
  productCardDirectsToModal,
} = companyData[companyName];

const log = (s) => {
	if (!hideConsoleLogs){
	  console.log(s);
	}
}

// TODO: merge ProductCardLegacy with the other product cards
const ProductCardLegacy = ({ url, setModalOpen, isModalOpen, setModalType, productUrl, setProductUrl, product_info, isFullScreen, handleQuantityChange, setCheckoutId, isOnShopify, cartData, getCartData, imageURL, username, currentConv, token, isMobile, toggleChat}) => {
  const [quantity, setQuantity] = useState(0);
  if (url[0] == "(") {
    url = url.slice(1);
  }
  if (url[url.length - 1] == ")") {
    url = url.slice(0, -1);
  }
  log(url);
  log(product_info);
  log(product_info[url]);

  const navigateToProductUrl = () => {
    window.location.href = product_info[url]["Product Link"]
    if (isMobile){
      toggleChat();
    }
  }


  return (
    <div className="product-card">
      <div className="product-card-first-row"
        onClick={() => {
          if (isFullScreen) {
            // In full-screen mode, just set the modal type and ensure the modal is open
            setModalType('ExpandedProduct');
            setProductUrl(url);
            if (!isModalOpen) {
              setModalOpen(true);
            }
          } else {
            // In non-full-screen mode, toggle the modal state
            if (productCardDirectsToModal) {
              setModalOpen(!isModalOpen);
              setModalType('ExpandedProduct');
              setProductUrl(url);
            }
            else {
              navigateToProductUrl()
            }
          }
        }} 
      >
        <div className="product-card-name">
          {product_info[url]["Product Name"]}
        </div>
        <div className="product-card-price">
          ${product_info[url]["Product Price"]}
        </div>
        <img className="product-card-image" src={product_info[url]["Product Image"]} alt={product_info[url]["Name"]}/>
      </div>
      {/*      <div className="product-card-add-to-cart" onClick={()=>{setModalOpen(!isModalOpen); setModalType('Cart')}}>
        Add to Cart
      </div>*/}
      {
        !isOnShopify &&
        <div className="cart-product-card-quantity-selector">
          <svg onClick={() => setQuantity(quantity - 1)} disabled={quantity <= 1} className="cart-product-card-quantity-down-arrow" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path d="M9 1L5 5L1 1" stroke="#8F959E" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span className="cart-product-card-quantity-selector-value">{quantity}</span>
          <svg onClick={() => setQuantity(quantity + 1)} className="cart-product-card-quantity-up-arrow" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
            <path d="M1 5L5 0.999999L9 5" stroke="#8F959E" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      }
      {
        isOnShopify &&
        <>
        { productCardDirectsToModal &&
          <button className='checkout-button pdp' onClick={() => {navigateToProductUrl()}}>View</button>
        }
        { !productCardDirectsToModal &&
          <ShopifyQuantityAdjuster variantId={product_info[url]["Variant ID"]} onQuantityChange={handleQuantityChange} setCheckoutId={setCheckoutId} cartData={cartData} getCartData={getCartData} username={username} currentConv={currentConv} token={token} />
        }
        </>
      }
    </div>
  )
};


export default ProductCardLegacy;