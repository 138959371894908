import { companyName, companyData } from "../constants";

const {
	URL,
} = companyData[companyName];

const handleSendToGorgias = (token, username, sendMessage, sender) => {
    return (
        fetch(`${URL}/api/write_message_to_gorgias`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({
            username: username,
            message: sendMessage,
            sender: sender
            }),
        })
    )
}

export default handleSendToGorgias
