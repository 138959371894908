import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { companyName, companyData } from './constants.js';

const {
	isShopifyDeployed
} = companyData[companyName];


// FIXME moving .liquid logic to react
const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.crossOrigin = 'anonymous';
    script.defer = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.head.appendChild(script);
  });
};

// FIXME moving .liquid logic to react
const setupShopifyEnvironment = async () => {
  // Dynamically load React and ReactDOM if not already loaded (optional)
  await Promise.all([
    loadScript('https://unpkg.com/react@18/umd/react.production.min.js'),
    loadScript('https://unpkg.com/react-dom@18/umd/react-dom.production.min.js')
  ]);

  // Create and configure the container for the React app
  const container = document.createElement('div');
  container.id = 'jenn-container-shopify';
  container.style.borderRadius = '21px';
  container.style.maxWidth = '348px';
  container.style.maxHeight = '150px';
  container.style.width = '100%';
  container.style.height = '100%';
  container.style.zIndex = '100';
  container.style.position = 'fixed';
  container.style.bottom = '20px';
  container.style.right = '20px';

  document.body.appendChild(container);

  // Mount the React app
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
};

// NOTE when uploading .js file for Shopify Integration make sure to set isShopifyDeployed = false in constant.js
if (!isShopifyDeployed) {
  // Development environment: Render the app normally
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <App />
  );
  reportWebVitals();
} else {
  // FIXME moving .liquid logic to react (remove)
  //Production environment: Define a global function to initialize the React app
  window.initializeJenn = function(containerId, isShopifyMobile) {
    try {
      const container = document.getElementById(containerId);
      if (!container) throw new Error("Container not found: " + containerId);

      const root = ReactDOM.createRoot(container);
      root.render(<App isShopifyMobile={isShopifyMobile}/>);
    } catch (error) {
      console.error("Failed to initialize Jenn:", error);
    }
  }
  // FIXME moving .liquid logic to react
  // document.addEventListener('DOMContentLoaded', setupShopifyEnvironment);
}