import { companyName, companyData } from "./constants";
// import './App_base.css';
// import './App_piperwai.css';
  // import './App_drloretta.css'
// import './App_drloretta.css';
// import './App_yurman.css';
// import './App_glamnetic.css';

// import './App_shiseido.css';
import React, { useState, useEffect, useRef } from 'react';
import Navigation from './Navigation';
// import AudioVisualizer from './AudioVisualizer';
import ChatInterface from './ChatInterface';
import LoginPage from './LoggingInPage';
// TODO: use LoggingInPage or LoginPage conditionally, using LoggingInPage if it is deployed on a website and LoginPage if deployed on demo site
// import LoginPage from './LoginPage';
import YurmanHome from './homePages/YurmanHome';
// import Products from './Products';
// import QuantityAdjuster from './QuantityAdjusterShopify';
// import CheckoutButton from './CheckoutButtonShopify';
// import VideoPlayer from './VideoPlayer';
// import shiseido_message_backdrop from './shiseido_message_backdrop.png';
import jenn_avatar from './jenn_avatar.png';
// import shi_video from './ashley_video2.mp4';
// import shi_video from './visualizer2.mov';
// import shi_video from './shiseido_dani_products.mp4'
import logEvent from './utils/logEvent';

const websiteURL = companyData[companyName]["websiteURL"]
const isOnShopify = companyData[companyName]["isOnShopify"]
const isShopifyDeployed = companyData[companyName]["isShopifyDeployed"]

let cssFileName = companyData[companyName].cssFileName;
// let cssFileName = './App_piperwai.css';
import(`./App_${cssFileName}.css`).then(() => { // This import function needs the ./App_ prefix and .css suffix to concatenate properly.
  console.log('CSS loaded');
}).catch(err => console.log('Failed to load CSS', err));

const App = ({isShopifyMobile}) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [token, setToken] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  // const [showChat, setShowChat] = useState(false);
  const [showChat, setShowChat] = useState(() => {
    // Retrieve the item from localStorage and parse it as JSON
    if (isOnShopify) {
      console.log("Onshopify")
      const saved = localStorage.getItem('showChat');
      return saved !== null ? JSON.parse(saved) : false;
    }
    return false
  });


  const [isVideo1Playing, setIsVideo1Playing] = useState(true);
  const [isVideo1Active, setIsVideo1Active] = useState(true);
  const video2Ref = useRef(null);
  const [videoIndex, setVideoIndex] = useState(-1);
  // const [videos, setVideos] = useState(['ashley message 1.mp4', 'ashley message 2.mp4', 'ashley message 3.mp4', 'ashley message 4.mp4', 'ashley message 5.mp4', 'ashley message 6.mp4', 'ashley message 7.mp4']);
  // const [videos, setVideos] = useState(['matilda message 1.mp4', 'matilda message 2.mp4', 'matilda message 3.mp4', 'matilda message 4.mp4', 'matilda message 5.mp4', 'matilda message 6.mp4', 'matilda message 7.mp4']);
  // const [videos, setVideos] = useState(['matilda the jenn 1.mp4', 'matilda the jenn 2.mp4', 'matilda the jenn 3.mp4', 'matilda the jenn 4.mp4', 'matilda the jenn 5.mp4', 'matilda the jenn 6.mp4', 'matilda the jenn 7.mp4']);
  const [videos, setVideos] = useState(['the jenn transparent silence.mp4', 'matilda the jenn 2.mp4', 'matilda the jenn 3.mp4', 'matilda the jenn 4.mp4', 'matilda the jenn 5.mp4', 'matilda the jenn 6.mp4', 'matilda the jenn 7.mp4']);
  const videoRefs = useRef(videos.map(() => React.createRef()));
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentConv, setCurrentConv] = useState(0);
  const [quizData, setQuizData] = useState([]);
  const [hasQuiz, setHasQuiz] = useState(false);
  const [quizGiven, setQuizGiven] = useState(false);


  // const handleVideoSwitch = (video) => {
  //   setIsVideo1Active(false);
  // };

  // const handleVideo2End = () => {
  //   setIsVideo1Active(true);
  // };

  // const handleVideoSwitch = () => {
  //     setIsVideo1Active(false);
  //     const nextIndex = (videoIndex + 1) % videos.length;
  //     if(videoIndex != -1){
  //       videoRefs.current[videoIndex].current.style.display = 'none';
  //     }
  //     // videoRefs.current[nextIndex].current.style.display = 'block';
  //     videoRefs.current[nextIndex].current.style.display = 'none';
  //     videoRefs.current[nextIndex].current.play();
  //     setVideoIndex(videoIndex + 1);
  // };

  // const handleVideoEnd = () => {
  //   console.log('video ended');
  //     setIsVideo1Active(true);
  // };

  useEffect(() => {
    localStorage.setItem('showChat', JSON.stringify(showChat));
    adjustStyles(); 
  }, [showChat]);

  // FIXME moving .liquid logic to react ( change ElementById name to match `index.js`)
  const adjustStyles = () => {
    const container = document.getElementById('jenn-container');
    if (container) {
      if (showChat) {
        if (isShopifyMobile) {
          container.style.maxHeight = '100%';
          container.style.maxWidth = '100%';
          container.style.right = '0';
          container.style.bottom = '0';
          container.style.borderRadius = '0';
        } else {
          container.style.maxWidth = '640px';
          container.style.maxHeight = '94%';
        }
      } else {
        if (isShopifyMobile) {
          container.style.right = '0px';
          container.style.bottom = '2px';
          container.style.maxWidth = '353px';
          container.style.maxHeight = '145px';
        } else {
          container.style.maxWidth = '348px';
          container.style.maxHeight = '150px';
        }
      }
    }
  };

  const toggleChat = () => {
    setShowChat(!showChat);
    toggleSize();
    const LogToggleChat = async () => {
      try {
        const eventData = {
          username: username,
          conversation_id: currentConv,
          url: window.location.href,
          event_type: 'click',
          event_target: 'toggleChat',
        };

        await logEvent(eventData, token);
        console.log('Event logged successfully');
      } catch (error) {
        console.error('Error logging event:', error);
      }
    };
    LogToggleChat();
    console.log('This is the showChat value:', showChat)
  };

  const handleFullScreenChange = (isFull) => {
    setIsFullScreen(isFull);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toggleSize = () => {
    const newSize = !isExpanded;
    setIsExpanded(newSize);

    // Post a message to the parent window with the new size state
    window.parent.postMessage({ widgetSize: newSize ? 'expanded' : 'initial' }, '*');
  };

  const handleUser = (user, tok) => {
    setUsername(user);
    setToken(tok);
  }

  useEffect(() => {
    const logPageView = async () => {
      try {
        const eventData = {
          username: username,
          conversation_id: currentConv,
          url: window.location.href,
          event_type: 'page_view',
          event_target: 'preload',
        };

        await logEvent(eventData, token);
        console.log('Event logged successfully');
      } catch (error) {
        console.error('Error logging event:', error);
      }
    };
    logPageView();
  }, []);

  if (username === null || token === null) {
    return (
      <LoginPage
        onLogin={handleUser}
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        setToken={setToken}
        setSessionId={setSessionId}
        currentConv={currentConv}
        setCurrentConv={setCurrentConv}
        setQuizData={setQuizData}
        setQuizGiven={setQuizGiven}
        setHasQuiz={setHasQuiz}
      />
    );
  } else {
    return (
      <>
        {/*{<AudioVisualizer audioSrc={shi_video} />}*/}
        {!isShopifyDeployed && companyName !== "yurman" && (<iframe 
          src={websiteURL}
          style={{ width: '100%', height: '100vh', border: 'none' }}
          sandbox="allow-scripts allow-same-origin"
          title="External Website"
        />)}
        {
          companyName === "yurman" &&
            <YurmanHome />
        }
        {/*<img className="message-background" src={shiseido_message_backdrop} alt="message-background"/>*/}
        {/*        <VideoPlayer src={shi_video} />
          <video 
            // src="jenn_silence_30_seconds.mp4"
            src="the jenn silent.mp4"
            className="jenn-avatar-video"
            // autoPlay
            loop
            // style={{ display: isVideo1Active ? 'block' : 'none' }}
            style={{ display: isVideo1Active ? 'none' : 'none' }}
          />
          {videos.map((src, index) => (
              <video
                  key={src}
                  ref={videoRefs.current[index]}
                  className="jenn-avatar-video"
                  src={src}
                  // autoPlay={isVideo1Active === false && index === videoIndex}
                  // style={{ display: isVideo1Active === false && index === videoIndex ? 'block' : 'none' }}
                  style={{ display: isVideo1Active === false && index === videoIndex ? 'none' : 'none' }}
                  onEnded={() => handleVideoEnd()}
              />
          ))}*/}
          <div className={`${showChat && !isFullScreen ? 'chat-interface-wrapper' : ' '}`} style={isOnShopify && !isShopifyMobile ? { borderRadius: '21px' } : {}}>
            {/*<ChatInterface username={username} token={token} screenWidth={windowSize[0]} toggleChat={toggleChat} showChat={showChat} onVideoSwitch={handleVideoSwitch} video2Ref={video2Ref} />*/}
            <ChatInterface
              username={username}
              token={token}
              screenWidth={windowSize[0]}
              toggleChat={toggleChat}
              showChat={showChat}
              setShowChat={setShowChat}
              isFullScreen={isFullScreen}
              onFullScreenChange={handleFullScreenChange}
              sessionId={sessionId}
              currentConv={currentConv}
              setCurrentConv={setCurrentConv}
              quizData={quizData}
              quizGiven={quizGiven}
              hasQuiz={hasQuiz}
            />
          </div>
      </>
    )
  }
};

export default App;