import React, { useState } from 'react';
import client from './shopify';
// import './App_piperwai.css';
// import './App_base.css';

function QuantityAdjuster({ variantId, onQuantityChange, setCheckoutId }) {
  const [quantity, setQuantity] = useState(0);

  const increaseQuantity = () => {
    updateCartQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    updateCartQuantity(quantity - 1);
  };

  const updateCartQuantity = (newQuantity) => {
    if (newQuantity <= 0) {
      // Here you could also handle removing the item from the cart if needed
      onQuantityChange(0);
      return;
    }

    const encodedVariantId = btoa(`gid://shopify/ProductVariant/${variantId}`);
    const lineItemsToAdd = [{ variantId: encodedVariantId, quantity: newQuantity }];

    // Assuming checkoutId is managed at a higher level and passed down.
    // You might need to adjust this to fit your application's state management.
    client.checkout.create().then((checkout) => {
      setCheckoutId(checkout.id);
      client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((checkout) => {
        console.log('Cart updated:', checkout.lineItems);
        // Optionally, update app state or local storage here with checkout.id and checkout.lineItems
        setQuantity(newQuantity);
        onQuantityChange(newQuantity);
      });
    });
  };

  return (
    <div className="cart-product-card-quantity-selector">
    <svg onClick={decreaseQuantity} disabled={quantity <= 1} className="cart-product-card-quantity-down-arrow" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M9 1L5 5L1 1" stroke="#8F959E" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span> {quantity} </span>
    <svg onClick={increaseQuantity} className="cart-product-card-quantity-up-arrow" xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M1 5L5 0.999999L9 5" stroke="#8F959E" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    </div>


  );
}

export default QuantityAdjuster;