import React from 'react';
import client from './shopify';

function CheckoutButton({ checkoutId }) {
  const handleCheckout = () => {
    if (!checkoutId) return;
    client.checkout.fetch(checkoutId).then((checkout) => {
      window.location.href = checkout.webUrl;
    });
  };

  return <button onClick={handleCheckout}>Checkout</button>;
}

export default CheckoutButton;
