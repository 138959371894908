export const fetchCartData = () => {
  return fetch(window.location.origin + '/cart.js', {
    method: 'GET'
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok {fetchCartData}');
    }
    return response.json();
  });
};

// adds to Cart, if already in cart set quantity to 1
export const addToCart = (variantId) => {
    return fetchCartData().then(cartData => {
      const existingItem = cartData.items.find(item => item.variant_id === variantId);
  
      if (existingItem) {
        // If the item already exists in the cart, increase its quantity by 1
        return changeCartItemQuantity(existingItem.key, existingItem.quantity + 1);
      } else {
        // If the item doesn't exist in the cart, add it with a quantity of 1
        return fetch(window.location.origin + '/cart/add.js', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ items: [{ id: variantId, quantity: 1 }] })
        }).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok {addToCart}');
          }
          return response.json();
        });
      }
    });
  };

// Updating this function to clearly reflect the change endpoint
export const changeCartItemQuantity = (variantId, quantity) => {
    return fetch(window.location.origin + '/cart/change.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: variantId,  
        quantity: quantity
      })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok {changeCartItemQuantity}');
      }
      return response.json();
    });
  };
