// import './App_base.css';
// import './App_piperwai.css';
// import './App_drloretta.css';
// import './App_possible.css';
// import './App_shiseido.css';
import React, { useState, useEffect } from 'react';
import logo from './logo.png';
import { companyName, companyData } from "./constants";
import Cookies from 'js-cookie';
import axios from 'axios';
import loadingGif from './V1.gif'

const {
  URL,
  hideConsoleLogs,
} = companyData[companyName];

function getAllCookies() {
  // Split document.cookie string into an array of "name=value" pairs
  const cookiesArray = document.cookie.split('; ');

  // Reduce the array into an object mapping cookie names to their values
  const cookies = cookiesArray.reduce((acc, currentCookie) => {
    const [name, value] = currentCookie.split('=');
    acc[name] = value;
    return acc;
  }, {});

  return cookies;
}

const log = (s) => {
	if (!hideConsoleLogs){
	  console.log(s);
	}
}

const LoginPage = ({ onLogin, username, password, setUsername, setPassword, setToken, setSessionId, currentConv, setCurrentConv, setQuizGiven, setQuizData, setHasQuiz }) => {

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve username and conversationId from localStorage
      let currentConversationId = localStorage.getItem('conversationId');
      let currentUsername = localStorage.getItem('username');
      // Validate username and conversationId, and update them if needed
      if (currentConversationId && currentUsername) {
        currentUsername = await validateConversation(currentConversationId, currentUsername);
      } else {
        currentUsername = await createConversation();
      }
      currentConversationId = localStorage.getItem('conversationId');
      setCurrentConv(currentConversationId);
      // Login
      fetch(URL + '/authentication/auth/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: currentUsername,
          password: "2345qwer"
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (!hideConsoleLogs){
            log('this is the data:', data);
          }
          if ('token' in data) {
            setToken(data['token']);
          }
        })
    };

    fetchData();
  }, []); // Make sure to properly manage dependencies if your logic depends on any props or state

  const validateConversation = async (convId, uname) => {
    try {
      const response = await axios.get(URL + '/api/validate_conversation', {
        params: { conversationId: convId, username: uname },
      });
      const { conversationId, username, quizData, quizGiven, hasQuiz } = response.data;
      // setConversationId(conversationId);
      setUsername(username);
      setQuizData(quizData);
      setQuizGiven(quizGiven);
      setHasQuiz(hasQuiz);
      localStorage.setItem('conversationId', conversationId);
      localStorage.setItem('username', username);
      return username
    } catch (error) {
      console.error('Error validating conversation:', error);
      return await createConversation();
    }
  };

  const createConversation = async () => {
    try {
      const response = await axios.get(URL + '/api/create_conversation');
      const { conversationId, username, quizData, quizGiven, hasQuiz } = response.data;
      // setConversationId(conversationId);
      setUsername(username);
      setQuizData(quizData);
      setQuizGiven(quizGiven);
      setHasQuiz(hasQuiz);
      localStorage.setItem('conversationId', conversationId);
      localStorage.setItem('username', username);
      return username;
    } catch (error) {
      console.error('Error creating conversation:', error);
    }
  };

  return (
    <div style={{
      // display: 'flex',
      display: 'none', //FIXME - fix loading state
      justifyContent: 'center',
      alignItems: 'center',
      height: '150px',
      width: '348px',
      borderRadius: '10px',
      backgroundColor: '#00cfb4',
      
    }}>
      <img style={{ height: '150px', }} src={loadingGif} alt="loading" />
    </div>
  );
};

export default LoginPage;