const sendEmail = (token, username, conversation_id) => {
    if (token !== null) {
      fetch(`${URL}/api/send_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
        body: JSON.stringify({
          username: username,
          conversation_id: conversation_id
        }),
      })
    }
  }
export default sendEmail