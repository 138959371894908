import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
// import './App_yurman.css';
import { companyName, companyData } from "./constants";
import micIcon from './mic.png';
import { RecordRTCPromisesHandler } from 'recordrtc';

const {
	URL,
	userInputType
} = companyData[companyName];

const SpeechTranscriber = ({ setInput, handleSend, input }) => {
    const [isRecording, setIsRecording] = useState(false);
    const recordRTCRef = useRef(null);

    const toggleRecording = async () => {
      if (isRecording) {
        await stopRecording();
      } else {
        await startRecording();
      }
    };

    const startRecording = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        recordRTCRef.current = new RecordRTCPromisesHandler(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          sampleRate: 44100,
          desiredSampRate: 16000,
          recorderType: RecordRTCPromisesHandler.StereoAudioRecorder,
        });
        await recordRTCRef.current.startRecording();
        setIsRecording(true);
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };

    const stopRecording = async () => {
        if (recordRTCRef.current) {
          await recordRTCRef.current.stopRecording();
          setIsRecording(false);
          await transcribeAudio();
        }
      };

      const transcribeAudio = async () => {
        if (recordRTCRef.current) {
          try {
            const blob = await recordRTCRef.current.getBlob();
            console.log('Audio blob:', blob);
            const formData = new FormData();
            formData.append('audio', blob, 'recording.webm');
      
            const response = await axios.post(URL + '/api/transcribe', formData);
            console.log('Transcription response:', response.data);
            setInput(response.data.transcript);
          } catch (error) {
            console.error('Error transcribing audio:', error);
          }
        } else {
          console.log('recordRTCRef.current is null');
        }
      };

      useEffect(() => {
        const sendTranscription = async () => {
          if (userInputType === "micOnly" && input.trim() !== '') {
            await handleSend();
            setInput('');
          }
        };
      
        sendTranscription();
      }, [input, userInputType, handleSend]);

  return (
    <div className={userInputType === "micOnly" ? "speech-transcriber" : "speech-transcriber-hybrid"}>
      <button
        className={`speech-transcriber-button ${isRecording ? 'recording' : ''}`}
        onClick={toggleRecording}
      >
        <img className="speech-transcriber-mic-icon" src={micIcon} />
      </button>
    </div>
  );
};

export default SpeechTranscriber;