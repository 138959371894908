// import './App_base.css';
// import './App_piperwai.css';
// import './App_drloretta.css';
// import './App_possible.css';
// import './App_yurman.css';
// import './App_shiseido.css';
import React, { useEffect, useState } from 'react';
import { companyName, companyData } from "./constants"
import logo from './mberry_logo.svg';

const {
	URL,
	hideConsoleLogs
  } = companyData[companyName];


const log = (s) => {
	if (!hideConsoleLogs){
	  console.log(s);
	}
}

const Navigation = ({ loadMessages, messagesLoaded, setCurrentConv, setMessages, token, username }) => {
  const [conversations, setConversations] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [navbarActive, setNavbarActive] = useState(false)

  log('this is the token:', token);

  const loadConversations = () => {
    fetch(`${URL}/api/conversations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Token ${token}`,
      },
      body: JSON.stringify({
        username: username,
      }),
    })
    .then(response => response.json())
    .then(data => {
      log(data);
      data['conversations'].map(conversation => {
        setConversations(prevConversation => [...prevConversation, { "conversation_id": conversation["id"]}]);
      })
      if(messagesLoaded){ loadMessages(data['conversations'].slice(-1)[0]['id'])}
      setCurrentConv(data['conversations'].slice(-1)[0]['id'])
    })
  }

  useEffect(()=> {
    if (token !== null) {
      loadConversations()
    }
  }, [])

  const createNewChat = () => {
    fetch(`${URL}/api/create_conversation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Token ${token}`,
      },
      body: JSON.stringify({
        username: username,
      }),
    })
    .then(response => response.json())
    .then(data => {
      let conversation = data['conversation']
      setConversations(prevConversation => [...prevConversation, { "conversation_id": conversation["id"]}]);
      setMessages(prevMessages => [...prevMessages, { text: conversation['message'], role: "assistant" }]);
      if(messagesLoaded){ loadMessages(conversation['id'])}
      setCurrentConv(conversation['id'])
    })
  }

  const goToMarketingSite = () => {
    window.location = "https://jennai.com";
  }

  return (
    <div>
{/*      <button className="nav-button" onClick={() => {setNavbarActive(!navbarActive)}}>
        Previous Chats
      </button>*/}
      <div className={`overlay ${navbarActive ? 'active' : ''}`} onClick={() => {setNavbarActive(!navbarActive)}}></div>
      <div className={`navigation ${navbarActive ? 'active' : ''}`}>
        <div className="navigation-items">
          <div className="logo-nav-container">
            <img className="logo-nav" src={logo} onClick={ goToMarketingSite } alt="logo"/>
            <div className="new-chat-container">
              <div className="new-chat-button" onClick={createNewChat}>
                Start chat
              </div>
            </div>
          </div>
          {conversations.map((conversation, index) => (
            <NavigationItem conversation={conversation} index={index} activeIndex={activeIndex} setActiveIndex={setActiveIndex} conversations={conversations} loadMessages={loadMessages} setCurrentConv={setCurrentConv} />
          ))}
        </div>
      </div>
    </div>
  );
};

const NavigationItem = ({ conversation, index, activeIndex, setActiveIndex, conversations, loadMessages, setCurrentConv}) => {
  const setConversationActive = (index) => {
    setActiveIndex(index);
  }

  return (
    <>
      <div key={(conversations.length-1-index)} className="nav-item" onClick={() => {setConversationActive(index); loadMessages(conversations[conversations.length-1-index].conversation_id); setCurrentConv(conversations[conversations.length-1-index].conversation_id)}}>
        <div className={`nav-item-text-${index === activeIndex ? 'active' : 'inactive'}`}>
          Chat #{conversations[conversations.length-1-index].conversation_id}
        </div>
      </div>
      <div className="nav-item-divider">
      </div>
    </>
  )
}

export default Navigation;